<template>
  <div class="progress-container">
    <div class="progress-bar-custom" :style="{ width: progressBarWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    progressBarWidth() {
      return Math.min(100, Math.max(0, this.percentage));
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  height: 4px;
  background-color: #ccc;
}

.progress-bar-custom {
  height: 100%;
  background-color:  var(--maincolor);
  text-align: center;
  line-height: 20px;
  color: #fff;
  transition: width 0.3s ease-in-out;
  border-radius: 5px;
}
</style>